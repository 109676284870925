"use client";

import styles from "./Welcome.module.scss";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { RoyalModal } from "@/app_components/generic/popup/themed/RoyalModal";
import RoyalUrButton from "@/app_components/generic/buttons/RoyalUrButton";
import Link from "next/link";
import { DISCORD_INVITE_URL } from "@/ts/business/constants";
import { useOptionalUser } from "@/app_components/user/UserContext";
import { RoyalBanner } from "@/app_components/banner/RoyalBanner";


export function Welcome() {
    const router = useRouter();
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const user = useOptionalUser();

    const welcomeParam = searchParams?.get("welcome");
    const [welcome, setWelcome] = useState(welcomeParam ?? null);

    useEffect(() => {
        if (welcomeParam) {
            // Remove welcome query param.
            const nextSearchParams = new URLSearchParams(searchParams.toString());
            nextSearchParams.delete("welcome");
            router.replace(`${pathname}?${nextSearchParams}`);
        }
    }, [welcomeParam, searchParams, router, pathname]);

    if (!user)
        return (<></>);

    return (
        <RoyalModal
            visible={welcome === "setup"}
            close={() => setWelcome(null)}>

            <div className={styles.title_section}>
                <RoyalBanner hideByLine={true} />
                <h2>
                    {`Welcome ${user.displayName}!`}
                </h2>
            </div>

            <p>
                We are excited to have you join our community of
                Royal Game of Ur players! If you&apos;d like to meet
                our other members, consider
                {" "}
                <Link href={DISCORD_INVITE_URL}>
                    joining our Discord server.
                </Link>
            </p>
            <p>
                I really hope you enjoy your games here!
                <br />
                ~ Padraig Lamont
            </p>

            <div className="block centre-text width-100% margin-top-sm">
                <RoyalUrButton
                    style="cta"
                    onClick={() => setWelcome(null)}>

                    Get Started
                </RoyalUrButton>
            </div>
        </RoyalModal>
    );
}
